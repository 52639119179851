<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('double_major_application_quotas')"
                        :isColumns="true"
                        :is-new-button="checkPermission('doublemajorquota_store')"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('double_major_application_quotas')"
                              :isColumns="true"
                              :is-new-button="checkPermission('doublemajorquota_store')"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id"/>
                        </b-form-group>
                        <b-form-group :label="$t('semester_count')">
                            <period-selectbox :to-range="5"
                                              v-model="datatable.queryParams.filter.semester_count">
                            </period-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('quota')">
                            <b-form-input type="number"
                                          min="0" max="1000"
                                          v-check-min-max-value
                                          v-model="datatable.queryParams.filter.quota"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="showModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="createFormModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Component
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import CommonModal from '@/components/elements/CommonModal';

    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
    import PeriodSelectbox from "@/components/interactive-fields/PeriodSelectbox"
    // Services

    import DoubleMajorQuotaService from "@/services/DoubleMajorQuotaService";
    // Page
    import CreateForm from './CreateForm';
    import UpdateForm from './UpdateForm';

    import ShowForm from './ShowForm';
    // Other
    import qs from 'qs';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            DatatableFilter,
            Datatable,
            CommonModal,
            FacultySelectbox,
            ProgramSelectbox,
            SemestersSelectbox,
            PeriodSelectbox,
            CreateForm,
            UpdateForm,
            ShowForm
        },
        metaInfo() {
            return {
                title: this.$t('double_major_application_quotas')
            };
        },
        data() {
            return {
                formId: null,
                formProcess: null,

                faculty_code: null,

                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line',
                                    permission:"doublemajorquota_show",
                                    callback: (row) => {
                                        this.showForm(row.id);
                                    },
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    hidden: false,
                                    permission: "doublemajorquota_update",
                                    callback: (row) => {
                                        this.updateFormShow(row.id);
                                    },

                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-2-line',
                                    hidden: false,
                                    permission: "doublemajorquota_delete",
                                    callback: (row) => {
                                        this.delete(row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id'),
                            field: 'id',
                            sortable: true,
                            hidden: true
                        },
                        {
                            label: this.$t('faculty'),
                            field: 'faculty',
                            sortable: true,
                            hidden: false,
                            formatFn: (value) => {
                                return this.getLocaleText(value, 'name');
                            }

                        },
                        {
                            label: this.$t('program'),
                            field: 'program',
                            sortable: true,
                            hidden: false,
                            formatFn: (value) => {
                                return this.getLocaleText(value, 'name');
                            }
                        },
                        {
                            label: this.$t('semester'),
                            field: 'semester',
                            sortable: true,
                            hidden: false,
                            formatFn: (value, row) => {
                                return row.semester.academic_year + ' / ' + this.getSemester(row.semester.semester);
                            }
                        },

                        {
                            label: this.$t('semester_count'),
                            field: 'semester_count',
                            sortable: true,
                            hidden: false
                        },
                        {
                            label: this.$t('quota'),
                            field: 'quota',
                            sortable: true,
                            hidden: false
                        },
                        {
                            label: this.$t('achievement_ranking_control'),
                            field: 'min_rank',
                            sortable: true,
                            hidden: false,
                            formatFn:(row)=>{
                                return row==true ? this.$t('yes'):this.$t('no')

                            }
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        methods: {
            filter() {
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return DoubleMajorQuotaService.getAll(config)
                                                   .then((response) => {
                                                       this.datatable.rows = response.data.data;
                                                       this.datatable.total = response.data.pagination.total
                                                   })
                                                   .finally(() => {
                                                       this.datatable.isLoading = false;
                                                   });
            },

            delete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                        icon: 'question'
                    })
                    .then((result) => {
                        DoubleMajorQuotaService.del(id)
                                                    .then((response) => {
                                                        this.filter();
                                                        this.$toast.success(this.$t('api.' + response.data.message));
                                                    })
                                                    .catch((error) => {
                                                        this.$toast.error(this.$t('api.' + error.data.message));
                                                    });
                    });
            },

            // Form Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            // Show
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.showModal.$refs.commonModal.show()
            },

            // Create Form
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Update Form
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.updateFormModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            }
        }
    }
</script>
