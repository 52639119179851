<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :faculty_code="faculty_code"
                                               :validate-error="errors[0]"
                                               :major_type="'A'"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_1')">
                            <semesters-selectbox :validate-error="errors[0]"
                                                 v-model="formData.semester_id">
                            </semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester_count" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_count')">
                            <period-selectbox :validate-error="errors[0]"
                                              :to-range="6"
                                              v-model="formData.semester_count">
                            </period-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="quota" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('quota')">
                            <b-form-input type="number"
                                          min="0" max="100"
                                          v-check-min-max-value
                                          v-model="formData.quota"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="min_rank" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('achievement_ranking_control')">
                            <b-form-select v-model="formData.min_rank"
                                           :options="options">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Seçiniz..</b-form-select-option>
                                </template>
                            </b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import PeriodSelectbox from "@/components/interactive-fields/PeriodSelectbox"
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"

    // Services

    import DoubleMajorQuotaService from "@/services/DoubleMajorQuotaService";
    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'


    export default {
        components: {
            FacultySelectbox,
            PeriodSelectbox,
            ProgramSelectbox,
            SemestersSelectbox,


            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                options: [
                    { value: 1, text: this.$t('yes') },
                    { value: 0, text: this.$t('no') },
                ],
                formLoading: false,
                faculty_code: null,
                formData: {
                    program_code: null,
                    semester_id: null,
                    quota: null,
                    point_type: null,
                    min_rank:null
                }
            }
        },
        mounted() {
            setTimeout(() => {
                this.$refs.formModalValidate.reset();
            }, 2000)
        },
        methods: {
            //TODO: Check point_type from api
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    DoubleMajorQuotaService.store(this.formData)
                                                .then(response => {
                                                    this.$emit('createFormSuccess', true);
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                    this.$refs.formModalValidate.reset();
                                                })
                                                .catch(e => {
                                                    this.showErrors(e, this.$refs.formModalValidate);
                                                })
                                                .finally(() => {
                                                    this.formLoading = false
                                                })
                }
            }
        }
    }
</script>
